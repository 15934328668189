var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"share"},[_c('g-header',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('span',[_vm._v(" ")])]},proxy:true},{key:"right",fn:function(){return [_c('van-icon',{attrs:{"name":"share"},on:{"click":_vm.handleShare}})]},proxy:true}])},[_c('span',[_vm._v("分享记录（"+_vm._s(_vm.shareCount)+"）")])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"share-records"},[_c('ul',[_vm._l((_vm.shareList),function(el){return _c('li',{key:el.id},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(el.created_at))]),_c('section',[_c('div',{staticClass:"browse"},[_vm._v(" "+_vm._s(el.viewed)+"人看过，"+_vm._s(el.curSee)+"人在看 ")]),_c('div',{staticClass:"avatar"},_vm._l((Math.min(5, el.curSee)),function(el){return _c('img',{key:el,attrs:{"src":"https://eggrj.oss-cn-hangzhou.aliyuncs.com/classifiedJins/1605516088198.png"}})}),0)])])}),(!_vm.shareList.length)?_c('div',[_c('img',{attrs:{"src":require("@/assets/img/empty.png"),"alt":""}}),_c('p',[_vm._v("暂无分享记录")])]):_vm._e()],2)]),_c('van-popup',{staticClass:"share-popup",attrs:{"position":"bottom"},model:{value:(_vm.inputMask),callback:function ($$v) {_vm.inputMask=$$v},expression:"inputMask"}},[_c('p',[_vm._v("分享给好友，指导他同步浏览")]),_c('section',[_c('div',{staticClass:"info"},[_c('p',[_vm._v("填写手机号，方便对方咨询")]),_c('p',[_vm._v("已分享"+_vm._s(_vm.shareCount)+"次")])]),_c('van-form',{attrs:{"show-error":false},on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"phone","placeholder":"请输入您的电话号码","rules":[
              { required: true, message: '请输入电话号码' },
              {
                pattern: /^\d*$/,
                message: '请输入正确格式的手机号',
              },
            ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{attrs:{"size":"small","native-type":"submit","type":"primary"}},[_vm._v("分享到微信")])]},proxy:true}]),model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1)])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shareMask),expression:"shareMask"}],staticClass:"share-mask",on:{"click":function($event){_vm.shareMask = false}}},[_c('img',{attrs:{"src":require("@/assets/img/share-arrow.png"),"alt":""}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }