
import { Component, Vue } from "vue-property-decorator";
import gHeader from "@/components/g-header.vue";
import { State } from "vuex-class";

@Component({
  components: {
    gHeader,
  },
})
export default class CVConsult extends Vue {
  @State sharePhone: any;
}
