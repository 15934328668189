
import { Component, Vue, Prop } from "vue-property-decorator";
import { addCart, isInCart } from "@/assets/js/Utils";
@Component({
  filters: {
    getFirst(str: string): string {
      return str ? JSON.parse(str)[0].replace("http:", "https:") : "";
    },
  },
})
export default class ProductCard extends Vue {
  @Prop({ type: Object }) private goodsInfo!: any;

  toDetail(): void {
    //跳转到商品详情页
    // this.$get("/big_home/get_product_model_detail", {id: this.goodsInfo.id}).then((res: any) => {
    //   console.log("获取单个数据模型", res);
    // })
    this.$router.push({
      name: "CV-Render3D",
      params: { id: this.goodsInfo.id },
    });
    // this.$router.push(`/colorCards/${this.goodsInfo.id}`);
    console.log("跳转到商品详情页");
  }

  addToCart(): void {
    // console.log("加入gouwuc");
    // console.log(this.goodsInfo);
    // this.goodsInfo = {...this.goodsInfo, isAdded: true};
    this.$emit("changeAdded", this.goodsInfo.id);
    addCart(this.goodsInfo);
    this.$toast("加入成功");
  }
}
