
import qs from "qs";
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import wx from "weixin-js-sdk";
import logo from "@/assets/img/logo.png";
import { configWechatAPI } from "@/assets/js/Authorize";
import gHeader from "@/components/g-header.vue";
@Component({
  components: {
    gHeader,
  },
})
export default class CVShare extends Vue {
  private title = "";
  private phone = ""; //用户电话号码
  // private avatarLen = 0; //要展示几个头像
  private shareList: any[] = []; //用户分享列表
  private shareCount = 0; //分享次数
  private inputMask = false; //是否展示弹出层
  private shareMask = false;
  @State openId: any;

  handleShare(): void {
    this.inputMask = true;
  }

  onSubmit(): void {
    // 添加分享参数
    this.$router
      .replace({
        path: this.$route.path,
        query: { shareId: this.phone },
      })
      .catch((e) => e);

    this.inputMask = false;
    this.shareMask = true;
    const url = window.location.href;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    configWechatAPI(url).then((res: any) => {
      // 需在用户可能点击分享按钮前就先调用
      wx.ready(function () {
        const options = {
          title: "算价格-柜子多少钱", // 分享标题
          desc: "嗨，我向你分享了买柜子，快来看看吧!", // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: logo, // 分享图标
          success: function () {
            // 设置成功
            console.log("分享成功");
            _this
              .$post(
                "https://home-dev.eggrj.com/api/share-store",
                qs.stringify({ phone: _this.phone, openid: _this.openId }) as any,
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then((res: any) => {
                _this.$showToast("创建分享成功！");
              });
          },
          fail: function (err: any) {
            console.log(err);
          },
        };
        if (wx.updateAppMessageShareData) {
          wx.updateAppMessageShareData(options);
        } else {
          wx.onMenuShareAppMessage(options);
        }
      });
    });
  }

  getShareCount(): void {
    //获取分享次数
    this.$get("https://home-dev.eggrj.com/api/share-count", {
      openid: this.openId,
    }).then((res: any) => {
      this.shareCount = res.data.data;
    });
  }

  getShareList(): void {
    //获取分享列表
    this.$get("https://home-dev.eggrj.com/api/share-index", {
      openid: this.openId,
    }).then((res: any) => {
      const data = res.data.data;
      const curTime = new Date().getHours(); //0 -23
      if (!data || !data.length) return; //如果返回的数据为空
      this.shareList = data.map((el: any) => {
        let min = 0;
        let max = 10;
        if (curTime >= 6 && curTime <= 18) {
          //当前时间段为白天
          min = 20;
          max = 30;
        }
        const curSee = Math.floor(min + Math.random() * (max - min + 1)); //当前在看的人数
        return { ...el, curSee };
      });
    });
  }

  created(): void {
    this.getShareList();
    this.getShareCount();
  }
}
