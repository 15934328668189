
import { Component, Vue } from "vue-property-decorator";
import home from "./Home/index.vue";
import cart from "./Cart/index.vue";
import share from "./Share/index.vue";
import consult from "./Consult/index.vue";
import order from "./Order/orderList.vue";
import { Mutation, State, Getter } from "vuex-class";
@Component({
  components: {
    home,
    cart,
    order,
    consult,
    share,
  },
})
export default class ComputeValue extends Vue {
  @State("computeValueNavList") navList: any;
  @State("computeValueIndex") navIndex: any;
  @Getter("curComputeValuePage") curPage: any;
  @Mutation updateComputeValueIndex: any;

  change(index: number) {
    this.updateComputeValueIndex(index);
  }

  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, "shareId")) {
      this.$store.commit("setSharePhone", this.$route.query.shareId);
    }
  }
}
