
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import gHeader from "@/components/g-header.vue";

@Component({
  components: {
    // gUserInfo  //用户信息地址列表组件
    gHeader,
  },
  filters: {
    getFirst(str: string): string {
      return str ? JSON.parse(str)[0].replace("http:", "https:") : "";
    },
  },
})
export default class CVOrderList extends Vue {
  private title = "我的订单";
  @State openId: any;
  private isList = true; //是否列表页
  private orderList = []; //订单列表
  toDetail(id: any) {
    //去订单详情页
    this.$router.push({
      name: "CV-OrderDetail",
      params: { id: String(id) },
    });
  }
  getOrderList() {
    this.$get("/big_home/get_order_list", {
      //获取订单列表
      open_id: this.openId,
    }).then((res: any) => {
      if (res.status == 200) {
        this.orderList = res.data.data.res;
      }
    });
  }
  activated() {
    this.getOrderList();
  }
}
